import i18n from 'i18next';
import {initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: {
          'consultations':{
            'title': 'My past encounters',
            'see-more': 'See More'
          },
          'patients': {
            'choose_medical_record': 'Choose your Medical Record',
            'no-medical-record-found': 'No medical records were found',
            'instructions_1': 'We found these medical records related with that phone number',
            'instructions_2': 'Please select the one you want to access',
          } ,
          'login-bar':{
            'step_1': 'ENTER YOUR MOBILE NUMBER',
            'step_2': 'VERIFICATION CODE',
            'step_3': 'CHOOSE YOUR MEDICAL RECORD',
          },
          'validation-form': {
            'title': 'Verification Code',
            'description': 'Please enter the code sent to '
          },
          'login-form':{
            'title': 'Welcome Patient',
            'description': 'Please enter with your phone number to continue',
            'country': 'Country',
            'mobile': 'Mobile number',
            'having_trouble': 'Having trouble?',
            'get_help': 'Get Help'

          },
          'medical-history': {
            'allergies': 'ALLERGIES', 
            'active-allergies': 'Active allergies', 
            'forms': {
              'save': 'Save',
              'deny_all': 'Deny all',
              'yes': 'Yes',
              'no': 'No',
              'denied': 'Denied'
            },
            'other-allergies': 'Other allergies', 
            'history': 'History',
            'my-medical-history': 'My Medical History' ,
            'my-vital-signs': 'My Vital Signs',
            'my-active-medications': 'My Active Medications',
            'my-past-encounters': 'My Past Encounters',
            'no-to-all': 'No to all',
            'denied-record': 'n/a',
            'pathological_records': {
              'prior_hospitalization': 'Prior Hospitalization',
              'prior_surgeries': 'Prior Surgeries',
              'diabetes': 'Diabetes',
              'thyroid_diseases': 'Thyroid Diseases',
              'hypertension': 'Hypertension',
              'heart_diseases': 'Heart Diseases',
              'injuries': 'Injuries',
              'name': 'PATHOLOGICAL HISTORY',
              'cancer': 'Cancer',
              'tuberculosis': 'Tuberculosis',
              'transfusions': 'Transfusions',
              'respiratory': 'Respiratory pathologies',
              'gastrointestinal': 'Gastrointestinal pathologies',
              'stds': 'Sexually transmitted diseases',
              'other': 'Other'
            },
            'nonpathological_records': {
              'physical_activity': 'Physical Activity',
              'smoking': 'Smoking',
              'alcoholism': 'Alcohol Intake',
              'drugs_abuse': 'Drug Abuse',
              'recent_immunization': 'Recent Immunization',
              'other': 'Other',
              'name': 'NONPATHOLOGICAL RECORDS'
            },
            'perinatal_records': {
              'last_menstrual_period': 'Last menstrual period',
              'cycle_length': 'Cycle length',
              'last_used_contraceptive_type': 'Last used contraceptive type',
              'assisted_conception': 'Assisted conception',
              'estimated_date_of_birth_by_lmp': 'Estimated date of birth by LMP',
              'final_edb': 'Final EDB',
              'pregnancy_notes': 'Pregnancy notes',
              'name': 'PERINATAL HISTORY'
            },
            'postnatal_records': {
              'birth_review': 'Birth review',
              'name_of_baby': 'Name of baby',
              'weight_at_birth': 'Weight at birth',
              'babys_health': 'Baby’s health',
              'infant_feeding': {
                'name': 'Infant feeding',
                'inputs': {
                  'breast_milk': 'Breast milk',
                  'breast_milk_substitute': 'Breast milk substitute',
                  'breast_milk_and_breast_milk_substitute': 'Breast milk and breast milk substitute'
                }
              },
              'emotional_state': 'Emotional State',
              'name': 'POSTNATAL HISTORY'
            },
            'psychiatric_records': {
              'family_history': 'Family History',
              'illness_awareness': 'Awareness of illness',
              'affected_life_aspects': 'Aspect of life affected by illness',
              'past_and_present_treatments': 'Past and present treatments',
              'family_and_friends_support': 'Support from family and friends',
              'family_life': 'Family life',
              'social_life': 'Social life',
              'work_life': 'Work life',
              'relationship_with_authority': 'Relationship with authority',
              'impulse_control': 'Impulse control',
              'deals_with_stress': 'Deals with stress',
              'name': 'PSYCHIATRIC HISTORY'
            },
            'gineco_obstetrics_records': {
              'menstruation_first_date': 'Menstruation First Date',
              'menstruation_last_date': 'Menstruation Last Date',
              'menstruation_characteristics': 'Menstruation Characteristics',
              'pregnancies': 'Pregnancies',
              'cervical_cancer': 'Cervical Cancer',
              'uterine_cancer': 'Uterine Cancer',
              'breast_cancer': 'Breast Cancer',
              'sexual_activity': 'Sexual Activity',
              'birth_control_method': 'Birth control methods used',
              'hormone_replacement_therapy': 'Hormone replacement therapy',
              'last_pap_smear': 'Last Pap smear',
              'last_mastography': 'Last Mastography',
              'other': 'Other',
              'name': 'OBSTETRIC AND GYNECOLOGICAL HISTORY'
            },
            'heredofamilial_records': {
              'diabetes': 'Diabetes',
              'heart_diseases': 'Heart Diseases',
              'hypertension': 'Hypertension',
              'thyroid_diseases': 'Thyroid Diseases',
              'other': 'Other',
              'name': 'FAMILY HISTORY'
            },
            'vaccines_mx': {
              'name': 'VACCINES (MEXICO)',
              'other': 'Other',
              'newborn': {
                'name': 'Newborn',
                'inputs': {
                  'bcg': 'BCG',
                  'hep_b': '1st Hepatitis B'
                }
              },
              'month_2': {
                'name': '2 months',
                'inputs': {
                  'penta': '1st Pentavalente Acelular',
                  'hep_b': '2nd Hepatitis B',
                  'rotavirus': '1st Rotavirus',
                  'neumo': '1st Neumococo'
                }
              },
              'month_4': {
                'name': '4 months',
                'inputs': {
                  'penta': '2nd Pentavalente Acelular',
                  'rotavirus': '2nd Rotavirus',
                  'neumo': '2nd Neumococo'
                }
              },
              'month_6': {
                'name': '6 months',
                'inputs': {
                  'penta': '3rd Pentavalente Acelular',
                  'hep_b': '3rd Hepatitis B',
                  'rotavirus': '3rd Rotavirus',
                  'flu': '1st Anti Flu (winter)'
                }
              },
              'month_7': {
                'name': '7 months',
                'inputs': {
                  'flu': '2nd Anti Flu (winter)'
                }
              },
              'month_12': {
                'name': '12 months',
                'inputs': {
                  'srp': '1st SRP',
                  'neumo': '3rd Neumococo'
                }
              },
              'month_18': {
                'name': '18 months',
                'inputs': {
                  'penta': '4th Pentavalente Acelular'
                }
              },
              'month_24': {
                'name': '2 years',
                'inputs': {
                  'flu': 'Flu Yearly (oct-jan)'
                }
              },
              'month_36': {
                'name': '3 years',
                'inputs': {
                  'flu': 'Flu Yearly (oct-jan)'
                }
              },
              'month_48': {
                'name': '4 years',
                'inputs': {
                  'dpt': 'DPT',
                  'flu': 'Flu Yearly (oct-jan)'
                }
              },
              'month_60': {
                'name': '5 years',
                'inputs': {
                  'flu': 'Flu Yearly (oct-jan)',
                  'vop': 'VOP/OPV'
                }
              },
              'month_72': {
                'name': '6 years',
                'inputs': {
                  'srp': '2nd SRP'
                }
              },
              'month_120': {
                'name': '11 years',
                'inputs': {
                  'vph': 'VPH'
                }
              }
            },
            'diet': {
              'name': 'DIET',
              'appetite_level': {
                'name': 'Appetite Level',
                'inputs': {
                  'excesive': 'Excesive',
                  'good': 'Good',
                  'fair': 'Fair',
                  'poor': 'Poor',
                  'none': 'None'
                }
              },
              'breakfast': 'Breakfast',
              'collation_morning': 'Collation Morning',
              'lunch': 'Lunch',
              'collation_afternoon': 'Collation Afternoon',
              'dinner': 'Dinner',
              'home_prepared': 'Prepared at home',
              'satiety': 'Satiety',
              'glasses_water_day': {
                'name': 'Number of water glasses per day',
                'inputs': {
                  'one_or_less': '1 or less',
                  'two_to_three': '2 to 3',
                  'four_or_more': '4 or more'
                }
              },
              'food_preferences': 'Food preferences',
              'food_diseases': 'Food diseases',
              'supplements': 'Supplements',
              'past_diets': 'Past diets',
              'ideal_weight': 'Ideal weight',
              'current_ailment': 'Current aliments related to weight',
              'past_ailment': 'Past ailments related to weight',
              'liquid_intake': 'Intake of liquids',
              'nutritional_education': 'Nutritional education',
              'other': 'Others'
            }
          },
          'attachments':{
            'title': 'My Attachments'
          },
          'prescriptions': {
            'title': 'My Prescriptions',
            'dose': 'Dose: ',
            'frequency': 'Frequency: ',
            'length': 'Length: ',
            'name': 'Name',
            'prescribed': 'Prescribed by',
            'date_prescribed': 'Date Prescribed',
            'instrucctions': 'Instructions:'

          },
          'lab-test-result': {
            'title': 'Lab Test Results',
            'not-enabled-1': 'All lab test results are disabled.',
            'not-enabled-2': 'Enable them here.',
            'basic_metabolic_panel': {
              'title': 'Basic metabolic panel',
              'elements': {
                'blood_urea_nitrogen': 'Blood urea nitrogen',
                'calcium': 'Calcium',
                'co2': 'CO2',
                'chloride': 'Chloride',
                'creatinine': 'Creatinine',
                'glucose': 'Glucose',
                'potassium': 'Potassium',
                'sodium': 'Sodium'
              }
            },
            'complete_blood_count': {
              'title': 'Complete blood count',
              'elements': {
                'hematocrit': 'Hematocrit',
                'hemoglobin': 'Hemoglobin',
                'platelet': 'Platelet',
                'red_blood_cells': 'Red blood cells',
                'reticulocyte': 'Reticulocyte',
                'white_blood_cell': 'White blood cell'
              }
            },
            'lipid_profile': {
              'title': 'Lipid profile',
              'elements': {
                'low_density_lipoprotein': 'Low-density lipoprotein',
                'high_density_lipoprotein': 'High-density lipoprotein',
                'total_cholesterol': 'Total cholesterol',
                'triglycerides': 'Triglycerides',
                'very_low_density_lipoprotein': 'Very low-density lipoprotein'
              }
            }
          },

          'profile':{
            'sidebar': {
              'my-health': 'My Health',
              'appointments': 'Appointmets',
              'messages': 'Messages'
            },
            'sections':{
              'my-health': 'My Health'
            }
          },
          'vital-signs':{
            'title': 'Vital Signs',
            'height': 'Height',
            'weight': 'Weight',
            'temperature': 'Temperature',
            'respiratory_rate': 'Respiratory Rate',
            'systole': 'Systole',
            'diastole': 'Diastole',
            'heart_rate': 'Heart Rate',
            'body_mass': 'Body Mass',
            'body_fat_percentage': 'Body Fat Percentage',
            'lean_body_mass': 'Lean Body Mass',
            'head_circumference': 'Head Circumference',
            'oxygen_saturation': 'Oxygen Saturation'
          },
          'appointments': {
            'appointment': "Appointment",
            'telehealth': "Telehealth",
            'title-requests': 'Appointment requests',
            'title-scheduled': 'Scheduled appointments',
            'organization-portals': 'Schedule an appointment from the portals',
            'status': {
              'scheduled': 'Scheduled',
              'confirmed': 'Confirmed',
              'pending_cancellation': 'Pending cancellation',
              'cancellation_request_accepted': 'Cancellation rejected (scheduled)',
              'cancellation_request_rejected': 'Cancellation rejected (scheduled)',
              'arrived': 'arrived',
              'started': 'Started',
              'pending': 'Pending',
            },
            'actions': {
              'confirm': {
                'title': 'Confirm',
                'modal': {
                  'title': 'Confirm appointment',
                  'message': 'Are you sure to confirm this appointment?',
                  'close': 'Close',
                  'accept': 'Confirm'
                },
                'success': 'Appointment confirmed successfully!',
                'error': 'An error occurred while confirming the appointment!'
              },
              'cancel': {
                'title': 'Cancel',
                'modal': {
                  'title': 'Cancel appointment',
                  'message': 'Are you sure to request the cancellation of this appointment?',
                  'close': 'Close',
                  'accept': 'Confirm'
                },
                'success': 'The cancellation request has been made successfully!',
                'error': 'An error occurred while creating the cancellation request!'
              },
              'close': 'Close'
            }
          }

        }
      },
      es: {
        translation: {
          'patients': {
            'choose_medical_record': 'Seleccione su historial médico',
            'no-medical-record-found': 'No se encontraron registros médicos'
          }
        }
      }
    },
    lng: 'en',
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
