import React  from 'react';
import './Home.css'
function  HomePage() {
    return (
    <div className="App">
      <header className="App-header">
        <p>
          Welcome to the real NimboChat
        </p>
      </header>
    </div>
  );
}
export default HomePage;
