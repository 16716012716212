import React, { useState,useEffect } from 'react';
import {db, getRoomInfo, writeMessage} from '../services/firebase'
import { ref, get, child, set} from "firebase/database";
import './Chat.scss'
import { Col, Button, Modal } from 'react-bootstrap';
import BubbleChat from '../components/BubbleChat';
import UploadImage from '../components/UploadImage'
import { MdAttachFile, MdOutlineSend } from "react-icons/md";
import {isMobile} from 'react-device-detect';
import Header from '../components/Header'
import { useSelector } from 'react-redux'

const usersChatRooms = 'users'
const chatRoomsPath = 'chatrooms';

function  Chat(props) {
  const user = props.user;
  const room = props.room || {};
  const name = props.name;

  const [chats, setChats] = useState([]);
  const [chatRoom, setChatRoom] = useState(room);
  const [messagesList, setMessagesList] = useState([]);
  const [content, setContent] = useState("");
  const [error, setError] = useState();
  const [showAttachment, setShowAttachment] = useState(false);

  const handleAttachShow = () => setShowAttachment(true);
  const handleAttachClose = () => setShowAttachment(false);

  const namesChatRooms = useSelector(state => state.firebase.data[usersChatRooms])
  const chatRooms = useSelector(state => state.firebase.data[chatRoomsPath])

  const chatName = React.useMemo(()=> {
    return _getChatName();
  }, [chatRoom]);

  function _getChatName(){
    let isDoctor = user.type === 'doctor';
    let idReceiver = isDoctor ? user.id : chatRoom.name.split("-")[0];
    let idTransmitter = isDoctor ? chatRoom.name.split("-")[1] : user.id;
    let nameChat = isDoctor ? `patient-${idTransmitter}` : `doctor-${idReceiver}`;
    
    return namesChatRooms[nameChat] ? `${namesChatRooms[nameChat].name}` : '---';
  }

  function _reloadChatName() {
    let nameChat = _getChatName();
    if (chatRooms[chatRoom.name]) {
      chatRooms[chatRoom.name]['chat_name'] = nameChat;
      chatRoom['chat_name'] = nameChat;
    }
  }

  if(room.hasOwnProperty('name') && chatRoom.hasOwnProperty('name')) {
    if(room.name !== chatRoom.name) {
      setChatRoom(room);
    }
  }


  var myRef = React.createRef();

  useEffect(() => {
    setChats([])
    if(chatRoom.hasOwnProperty('messages') || room.hasOwnProperty('messages')) {
      getArrayMessages();
    }
  }, [chatRoom] );

  useEffect(() => {
    setChats([]);
    getRoomInfo(chatRoom.name, [], [], ()=> {}, setChatRoom);
    setChatRoom(room);
    getArrayMessages();
  }, [room] );

  useEffect(()=> {
    async function getChatInfo(){
      var auxChats = []
      await Promise.all(
        messagesList.map( async msg_id => {
        let xs = await getMessaage(msg_id);
        if (xs) {
          auxChats.push(xs);
        }
     }))
    setChats(auxChats);
    }
    getChatInfo();
  } , [messagesList]);

  function getArrayMessages() {
    var messagesListAux=  [];
    if(chatRoom.messages){

      Object.keys(chatRoom.messages).forEach((ch) => {
        messagesListAux.push(ch);

      });
    } else {
      setChats([]);
    }
    setMessagesList(messagesListAux);
  }



  async function getMessaage(msg){
    const chatroomsRef = ref(db, 'messages');

    const messageInfo =  get(child(chatroomsRef, '/' + msg)).then((snapshot) => {
        if (snapshot.exists()) {
          return  snapshot.val();

        } else {
          console.log("No data available");
        }
      }).catch((error) => {
        console.error(error);
      });
      return messageInfo ;
  }

  useEffect(() => {
    const chatArea = myRef.current;
    chatArea.scrollBy(0, chatArea.scrollHeight);

  },[myRef]);



  function handleChange(event) {
    setContent(event.target.value);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    if(content !== undefined && content.trim() !== ""){
      const chatArea = myRef.current;
      try {
        let us = user.type + '-'+ user.id

        writeMessage(content, us, room.name, 'text')
        setContent('');
        setTimeout(() => {
          getRoomInfo(chatRoom.name, [], [], ()=> {}, setChatRoom);
        }, 500);
        chatArea.scrollBy(0, chatArea.scrollHeight);
        setError();
      } catch (error) {
      console.log(error);
        setError(error);
      }
    }
    else{
      setError('no puedes mandar un mensaje vacio')
    }
  }


  function TextArea(){
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        handleSubmit(event)
      }
    }
    return (
      <textarea className="form-control text-meessage" name="content" onChange={handleChange} onKeyDown={handleKeyDown} value={content}></textarea>
    )

  }


  function header(){
    if(!isMobile){
      return(
        <Header name={chatName}></Header>
      )
    }
  }
  return (
    <React.Fragment >
      <div className="chat-area" >
        {header()}

        <div className={"chat-panel " + (isMobile? "mobile" : "")} ref={myRef}>
          { chats.length > 0 ? (
            chats.map((chat, index) => {
              return ( <BubbleChat chat={chat} user={user} key={index}/> )

            })
          ) : (<></>) }
          

        </div>

        <div className='container-text'>
          {error ? <p className="text-danger">{error}</p> : null}
          <Col md={12}>
            <div className="chat-box-tray">
              <Button onClick={handleAttachShow} variant="light" className='btn-attach'> <MdAttachFile/> </Button>
              {TextArea()}
              <Button variant="light" className='btn-submit'  onClick={handleSubmit}> <MdOutlineSend/> </Button>

            </div>
          </Col>
          <Modal show={showAttachment} onHide={handleAttachClose}>
            <Modal.Header closeButton>
              <Modal.Title>Adjuntar imagen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <UploadImage user={user} room={chatRoom.name} close={handleAttachClose}/>
            </Modal.Body>
          </Modal>

        </div>
      </div>

    </React.Fragment>
  );
}
export default Chat;


