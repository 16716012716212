import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import ReactLoading from 'react-loading'

import {getFBToken} from '../services/Api'
import {isMobile} from 'react-device-detect';
import ChatContainer from './ChatContainer'
import ChatList from '../components/ChatList';

import { useSelector } from 'react-redux'
import { useFirebase, isLoaded } from 'react-redux-firebase'

const usersPath = 'users';
const userLoggedReduxName = 'userLogged'
const chatRoomsPath = 'chatrooms';
const usersChatRooms = 'users'


function  ChatRoom(props) {
  const firebase = useFirebase()
  const userLogged = useSelector(state => state.firebase.data[userLoggedReduxName])
  const chatRooms = useSelector(state => state.firebase.data[chatRoomsPath])
  const namesChatRooms = useSelector(state => state.firebase.data[usersChatRooms])


  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  const user_id = params.get('user_id')
  var type = params.get('type')

  const token = params.get('token')
  const [currentUser, setCurrentUser] = useState("");
  const [chatList, setChatList] = useState([]);
  const [error, setError] = useState(false);
  const [roomInfo, setRoomInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [currentRoom, setCurrentRoom] = useState({});

  const userType = React.useMemo(()=> {
    return (type === 'patient-mobile') ? 'patient' : type
  }, [type]);


  async function loginFarebase(token){
    let logged = await
    firebase.login({
      token: token
    })
    .then((response) =>{
      try {
        let user = `${userType}-${user_id}`;
        let path = `${usersPath}/${user}`;

        setCurrentUser(user);
        firebase.watchEvent('value', path, userLoggedReduxName);

      } catch (error) {
        setError(true);
      }
      return response;
    })
    .catch((error) => {
      setError(true);
      console.error(error.message);
      return false;
    });
  }

  async function  componentDidMount() {
    let response = await getFBToken(user_id,type, token)
    if(response && response.status === 200){
      loginFarebase(response.data.token)
    }else {
      setLoading(false);
      setError(true);
    }
  }



  useEffect(() => {
    componentDidMount();
  }, []);

  useEffect(() => {
    if(userLogged) {
      getChatRooms();
    }
  }, [userLogged]);

  async function getChatRooms() {
    var auxList=  [];
    if(userLogged && userLogged.chatrooms){
      Object.keys(userLogged.chatrooms).forEach((ch) => {
        if(_validChatRoom(ch)) {
          auxList.push(ch);
        }
      });

      setChatList(auxList);
    } else {
      setLoading(false);
    }
    for (const chat of auxList) {
      await firebase.watchEvent('value', chatRoomsPath + "/" + chat, "",['orderByChild=updated_at', '']);
    }
  }

  function _validChatRoom(chat){
    let splitChat = chat.split("-").filter((c) => c !== "");
    return splitChat.length === 2;
  }

  useEffect(() => {
    if(chatRooms) {
      let cRooms = Object.values(chatRooms);
      if( cRooms.length === chatList.length ) {
        cRooms.map((room) => {
          membersToArray(room);
        });
      }
    }
  }, [chatRooms]);

  function membersToArray(roomInfo) {
    let membersListAux = [];
    if(roomInfo.members){
      Object.keys(roomInfo.members).forEach((member) => {
        membersListAux.push(member);

      });
    }
    getMemberInfo(roomInfo, membersListAux);
  }

  async function getMemberInfo(roomInfo, membersList){
    var auxMembers = ''
      await Promise.all(
        Object.values(membersList).map( async (member_id) => {
        if (member_id !== currentUser){
        let path = `${usersPath}/${member_id}`;
        if(!_findAndSetChatName(roomInfo, member_id)) {
          let response = await firebase.watchEvent('value', path);
        }

        }
      }))
  }

  function _findAndSetChatName(roomInfo, member_id) {
    let nameChatRoom = namesChatRooms && namesChatRooms.hasOwnProperty(member_id) ?
      namesChatRooms[member_id] : {};
    if (Object.keys(nameChatRoom).length > 0){
      if(chatRooms[roomInfo.name].hasOwnProperty('chat_name')) {
        return true
      } else {
        chatRooms[roomInfo.name]['chat_name'] = nameChatRoom.name;
        return true
      }
    }
    return false;
  }

  useEffect(() => {
    let lnamesCR = namesChatRooms ? Object.values(namesChatRooms).length : 0;
    let lcR = chatRooms ? Object.values(chatRooms).length : 0;
    if(namesChatRooms && lnamesCR > 0 && lnamesCR === lcR) {
      Object.keys(namesChatRooms).map((nameChat) => {
        let idReceiver = userType === 'doctor' ? nameChat.split("-")[1] : currentUser.split("-")[1];
        let idTransmitter = userType === 'doctor' ? currentUser.split("-")[1] : nameChat.split("-")[1];

        let nameChatRoom = `${idTransmitter}-${idReceiver}`;
        if (chatRooms[nameChatRoom]) {
          chatRooms[nameChatRoom]["chat_name"] = `${namesChatRooms[nameChat].name}`;
        }
      });
      setLoading(false);
    }
  }, [namesChatRooms]);

  if (loading){
    return (
      <div className='chat-container'>
        <Col md='12' className='chat-list'>
          <ReactLoading type='spinningBubbles' color="#000" />
        </Col>
      </div>
    )
  }

  if (isMobile) {
    return <ChatList rooms={chatList} user={userLogged} error={error}/>
  }
  
  return (
    <>
      <ChatContainer chatList={chatList} user={userLogged}/> 
    </>
  );
}
export default ChatRoom;
