import firebase from "firebase/compat/app";
import { signInWithCustomToken, getAuth } from "firebase/auth";
import {  getDatabase, ref, onValue, set, child, push, get } from "firebase/database";
import ENV from '../environment';

const fb_api_key = ENV.fb_api_key;
const fb_auth_domain = ENV.fb_auth_domain;
const fb_db_url = ENV.fb_db_url;
const config = {
  apiKey: fb_api_key,
  authDomain: fb_auth_domain,
  databaseURL: fb_db_url
};

firebase.initializeApp(config);

const db = getDatabase();
let auth = getAuth();
async function login(token){
 return await signInWithCustomToken(auth, token)
  .then((userCredential) => {
    return true
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.error(errorCode, errorMessage);
    return false;
  });
}

async function writeUserData(patient) {
  const aux = {
      id: patient.id,
      name: patient.full_name,
      organization: patient.organization_name,
      doctor: patient.doctor_name,
      phone: patient.telephone2,
      type: 'patient'
    }

  Object.keys(aux).forEach( k => {
    writeByChild(k, patient, aux[k])
  })

}
function writeByChild(child, patient, value){
  try {
    set(ref(db, 'users/' + patient.id + '/' + child), value);
  } catch (error) {
    console.log(error);
  }
}

async function writeMessage(content, user, room, type){
  try {
    const message =  await push(ref(db, 'messages'), {
      content: content,
      room: room,
      timestamp: Date.now(),
      type: type,
      user: user,
    });
    set(ref(db, 'chatrooms/' + room + '/messages/' + message.key), true);
    set(ref(db, 'chatrooms/' + room + '/members/' + user), true);
    set(ref(db, 'chatrooms/' + room + '/updated_at'), Date.now());
  } catch (error) {
    console.log(error);
  }
}

async function getRoomInfo(room, rooms, chatRooms, setChatRooms = () => {}, setRoomInfo = () => {}){
  const chatroomsRef = ref(db, 'chatrooms');
  if(room){
    onValue(child(chatroomsRef, '/' + room) , (snapshot) => {
      if (snapshot.exists()) {
        const roomInfo = snapshot.val();

        if (Object.values(roomInfo).length > 0) {
          let cRooms = chatRooms
          let existCRoom = existChatRoom(chatRooms, roomInfo.name);
          if (existCRoom !== -1) {
            cRooms[existCRoom] = roomInfo;
          } else {
            cRooms.push(roomInfo);
          }
          if (cRooms.length === rooms.length) {
            cRooms = orderChatRooms(cRooms);
          }
          setChatRooms(cRooms);
        }

        setRoomInfo(roomInfo);
      } else {
        console.log("No data available");
      }

    });
  }
}

function existChatRoom(cRooms, room_id) {
  return cRooms.findIndex((room) => {
    return room.name === room_id;
  });
}

function orderChatRooms(cRooms) {
  if(cRooms.length > 1) {
    cRooms.sort((a, b) => {
      let updatedAtA = a.hasOwnProperty("updated_at") ? a.updated_at : 0;
      let updatedAtB = b.hasOwnProperty("updated_at") ? b.updated_at : 0;

      if(updatedAtA > updatedAtB) {
        return -1;
      }

      if(updatedAtA < updatedAtB) {
        return 1;
      }

      return 0;
    });
  }
  return cRooms;
}

async function getUserInfo(id){
  const chatroomsRef = ref(db, 'users');
  const x =  get(child(chatroomsRef, '/' + id)).then((snapshot) => {
      if (snapshot.exists()) {
        const userInfo = snapshot.val();
        return userInfo;
      } else {
        console.log("No data available");
      }
    }).catch((error) => {
      console.error(error);
    });
  return await  x
}

export  { db, login, writeUserData, getRoomInfo, writeMessage, getUserInfo};