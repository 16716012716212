import React , {useState} from 'react';
import { uploadFile } from '../services/S3Uploader/ReactS3';
import {writeMessage} from '../services/firebase'
import {config} from '../services/s3'

function UploadImage (props)  {

  const user = props.user;
  const room = props.room;
  const close = props.close

  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileInput = (e) => {
      setSelectedFile(e.target.files[0]);
  }
  const handleUpload = async (file) => {
    close()
    uploadFile(file, config)
    .then(data => {
      console.log(data);
      let us = user.type + '-'+ user.id
      writeMessage(data.location, us, room, 'image')

    })
    .catch(err => console.error(err))
  }
  return (
    <div>
      <input type="file" onChange={handleFileInput} accept="image/png, image/jpeg" className='file-input'/>
      <button onClick={() => handleUpload(selectedFile)}> Subir</button>
    </div>
  )
}

export default UploadImage;