import React from 'react'
import './BubbleChat.scss'
function BubbleChat(props){
  const user = props.user
  const chat = props.chat
  const aux_user = `${user.type}-${user.id}`

  function formatTime(timestamp) {
    const d = new Date(timestamp);
    const time = `${d.getDate()}/${(d.getMonth()+1)}/${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}`;
    return time;
  }

  return (
    <div className="no-gutters">
      <p key={chat.timestamp} className={"chat-bubble " + (aux_user === chat.user ? "current-user" : "")}>
        { chat.type ==='image' ?  <img src={chat.content} alt="img-msg" className='img-msg'/> : chat.content }
      </p>
      <p className={(aux_user === chat.user ? "current-user" : "")}>
        <span className={"chat-time " + (aux_user === chat.user ? "current-user" : "")}>{formatTime(chat.timestamp)}</span>
      </p>
    </div>
  )
}
export default BubbleChat;