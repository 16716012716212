import axios from "axios";
import ENV from '../environment';

const API_URL = ENV.apiUrl
async function getFBToken(uid,type,token){
  try {
    const url =  `${API_URL}/chat?uid=${uid}&type=${type}&token=${token}`
    const response = await axios.get(url);
    return response;

  }catch (e) {
    console.log('something wrong');
  }
}
export {
  getFBToken
};
