import React from 'react';
import noUser from "../assets/images/no_user.png";
// import { useTranslation } from 'react-i18next';
 function NoUser() {
// const { t } = useTranslation();

  return (
    <div>
      <img src={noUser} alt="Logo" />
      <p>No user</p>
      <style jsx>
      {`
        p {
            color: #50637d;
            text-align: center;
            font-size:30px;
            font-weight:bold;
            margin:40px;
        }
        img {
          display: block;
          margin-left: auto;
          margin-right: auto;
        }
      `}
    </style>
    </div>
  );
}

export default NoUser;
