import React from 'react'
import './Header.scss'
import {isMobile} from 'react-device-detect';
function Header(props){
  const name = props.name;

  const getInitials = (name, fallback = "?") => {
    if (!name || typeof name !== "string") return fallback;
    return name
      .replace(/\s+/, " ")
      .split(" ") // Repeated spaces results in empty strings
      .slice(0, 2)
      .map((v) => v && v[0].toUpperCase()) // Watch out for empty strings
      .join("");
  };

  return(
    <div className="settings-tray">
      <div className="friend-drawer no-gutters friend-drawer--grey">
        <div className={"initials " + (isMobile? "mobile" : "")} style={{backgroundColor: '#7B7BFF'}}>{getInitials(name)}</div>
        <div className="text">
          <h6>{name}</h6>
        </div>
      </div>
    </div>
  )
}

export default Header;
