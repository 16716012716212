import './App.css';
import './i18n';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PublicRoute from './decorators/PublicRoute';
import Home from './pages/Home'
import ChatRoom from './pages/ChatRoom';
import 'bootstrap/dist/css/bootstrap.min.css';
import ChatMobile from './pages/ChatMobile';
function App() {

  return (
    <Router>
      <Routes>
        <Route exact path='/' element={<PublicRoute/>}>
          <Route exact path='/' element={<Home/>}/>
        </Route>
        <Route exact path='/chatroom' element={<PublicRoute/>}>
          <Route exact path='/chatroom' element={<ChatRoom/>}/>
        </Route>
        <Route exact path='/chat' element={<PublicRoute/>}>
          <Route exact path='/chat' element={<ChatMobile/>}/>
        </Route>
      </Routes>
    </Router>
  )
}
export default App;
