import React, { useEffect, useState } from 'react';
import { ref, child, onValue} from "firebase/database";
import {db, getUserInfo } from '../services/firebase'
import './RoomCard.scss'
function RoomCard(props) {
  const [membersName, setMembersName] = useState('---');
  const room_id = props.room;
  const user = props.user;
  let roomSelected = props.roomSelected;
  const [currentRoom, setCurrentRoom] =  useState(props.room);

  useEffect(() => {
    async function  componentDidMount() {
      try {
        const refRooms = ref(db, 'chatrooms')
      } catch (error) {
        console.log(error);
      }
    }
    componentDidMount();
   }, [room_id]);


  useEffect(()=> {
    if(currentRoom.members){
      getMemberInfo();
    }

  }, [currentRoom]);

  async function getMemberInfo(){

    var auxMembers = ''
    await Promise.all(
      Object.keys(currentRoom.members).map( async member_id => {
      if (member_id !== `${user.type}-${user.id}`){
        let xs = await getUserInfo(member_id);
        auxMembers = auxMembers + `${xs['name']}, `;
      }
    }))
    auxMembers = auxMembers.substring(0, auxMembers.length -2)
    setMembersName(auxMembers);
  }

  const getInitials = (name, fallback = "?") => {
    if (!name || typeof name !== "string") return fallback;
    return name
      .replace(/\s+/, " ")
      .split(" ") // Repeated spaces results in empty strings
      .slice(0, 2)
      .map((v) => v && v[0].toUpperCase()) // Watch out for empty strings
      .join("");
  };


  return (
    <React.Fragment>
      <div className={`chat-card chat-card--onhover ${roomSelected === currentRoom.name ? "chat-card--selected" : ""}`}>
        <div className='initials' style={{backgroundColor: '#7B7BFF'}}>{getInitials(membersName)}</div>
        <div className='text'>
          <h6>{membersName}</h6>
        </div>
      </div>
    </React.Fragment>
  );
}

export default RoomCard;
