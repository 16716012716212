import React, { useState,useEffect } from 'react';
import './Chat.scss'
import { HiChevronLeft } from "react-icons/hi";
import { useNavigate, useLocation } from 'react-router-dom';
import Chat from './Chat';
import Header from '../components/Header';
import { getRoomInfo, getUserInfo} from '../services/firebase'
function  ChatMobile(props) {
  const location = useLocation();
  const {user, room, name, chatRoom} = location.state

  const navigate = useNavigate();

  const [membersName, setMembersName] = useState('---');
  const [membersList, setMembersList] = useState([]);
  const [messagesList, setMessagesList] = useState([]);
  useEffect(()=> {
    if(membersList.length > 0){
      getMemberInfo();
    }
  }, [membersList]);

  async function getMemberInfo(){
    var auxMembers = ''
      await Promise.all(
        Object.values(membersList).map( async member_id => {
        if (member_id !== `${user.type}-${user.id}`){
          let xs = await getUserInfo(member_id);
          auxMembers = auxMembers + `${xs['name']} `;
        }
      }))
      setMembersName(auxMembers);

  }

  useEffect(() => {
    membersToArray(chatRoom);
  }, [chatRoom]);

  function membersToArray(roomInfo) {
    let membersListAux = [];
    if(roomInfo.members){
      Object.keys(roomInfo.members).forEach((member) => {
        membersListAux.push(member);

      });
    }

    setMembersList(membersListAux);
  }


  return(
    <React.Fragment>
      <div className='mobile-header'>
        <HiChevronLeft  className='back' onClick={() => navigate(-1)}/>
        <Header name={membersName}/>
      </div>

      <Chat user={user} room={chatRoom} name={name}/>
    </React.Fragment>

  )
}
export default ChatMobile;