import React, { useEffect, useState } from 'react';

import ReactLoading from 'react-loading'
import { ref, onValue, child } from "firebase/database";
import {db, login, getRoomInfo, getUserInfo} from '../services/firebase'
import RoomCard from '../components/RoomCard';
import Chat from './Chat';
import { Col, Row, FormControl } from 'react-bootstrap';
import {getFBToken} from '../services/Api'
import NoUser from '../components/NoUser';

import { useSelector } from 'react-redux'
import { isEmpty } from 'react-redux-firebase'

import { orderChatRooms } from '../helpers/helpChatRoom';


import './ChatContainer.scss'

function  ChatContainer(props) {
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  const user_id = params.get('user_id')
  const chatRooms = useSelector(state => state.firebase.data['chatrooms'])

  var type = params.get('type')
  const [membersName, setMembersName] = useState('---');
  const token = params.get('token')
  const [currentUser, setCurrentUser] = useState(props.user);
  const [chatList, setChatList] = useState(props.chatList);
  // const [chatRooms, setChatRooms] = useState([]);
  const [currentRoom, setCurrentRoom] = useState({});
  const [membersList, setMembersList] = useState([]);
  const [messagesList, setMessagesList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [valid, setValid] = useState(false);
  const [roomInfo, setRoomInfo] = useState({});
  const [currentSearch, setCurrentSearch] = useState('');

  async function  componentDidMount() {
    let response = await getFBToken(user_id,type, token)
    if(response && response.status === 200){
      setValid(true)
      setLoading(false)
    }else{
      setValid(false)
      setLoading(false)
    }
  }

  function changeChat(_room){
    membersToArray(_room);
    setCurrentRoom(_room);
  }

  function membersToArray(roomInfo) {
    let membersListAux = [];
    if(roomInfo.members){
      Object.keys(roomInfo.members).forEach((member) => {
        membersListAux.push(member);

      });
    }

    setMembersList(membersListAux);
  }

  useEffect(()=> {
    if(membersList.length > 0){
      getMemberInfo();
    }
  }, [membersList]);

  async function getMemberInfo(){
    var auxMembers = ''
      await Promise.all(
        Object.values(membersList).map( async member_id => {
        if (member_id !== `${currentUser.type}-${currentUser.id}`){
          let xs = await getUserInfo(member_id);
          auxMembers = auxMembers + `${xs['name']}, `;
        }
      }))
      auxMembers = auxMembers.substring(0, auxMembers.length -2)
      setMembersName(auxMembers);

  }

  const handleSearch = (searchQuery) => {
    setCurrentSearch(searchQuery);
  };

  const arrayChatRooms = React.useMemo(()=> {
    return isEmpty(chatRooms) ? [] : orderChatRooms(Object.values(chatRooms));
  }, [chatRooms]);

  const filteredChatList = React.useMemo(()=> {
    const _filteredChats = !isEmpty(chatRooms) ? arrayChatRooms.filter((chatRoom) => {
      return chatRoom.hasOwnProperty('chat_name') ? chatRoom.chat_name
          .toUpperCase()
          .includes(currentSearch.toUpperCase()) : true;
    }) : [];

    return _filteredChats;
  }, [chatRooms, currentSearch]);

  useEffect(()=> {
    if(!isEmpty(chatRooms) && Object.keys(currentRoom).length === 0){
      console.log("****///SETEO EL CURRENT ROOOOOOOOOONM");
      setCurrentRoom(arrayChatRooms[0]);
    }
  }, [chatRooms]);

  function renderChat(){

    if(currentUser.name){
      return(
        <Chat user={currentUser} room={currentRoom} name={membersName}/>
      )
    }
  }

  useEffect(() => {
    componentDidMount();
  }, []);

  function showLoading(){

    if (loading){
      return (
        <div className='chat-container'>
          <Col md='12' className='chat-list'>
            <ReactLoading type='spinningBubbles' color="#000" />
          </Col>
        </div>
      )
    }
    if(valid){
      return(
        <div className='chat-container'>
          <Row className='chat-row'>
            <Col md={3} className='chat-list'>
              <div className='input-search'>
                <FormControl 
                  ype="text"
                  placeholder='Buscar chat'
                  className='search-box'
                  onChange={(e) => handleSearch(e.target.value)}></FormControl>
              </div>
              {
                ((!isEmpty(chatRooms)) && Object.values(chatRooms).length > 0 )?(
                  Object.values(filteredChatList).map(room => {
                    return (
                      <div onClick={changeChat.bind(0,room) }  key={room.name}>
                        <RoomCard room={room} user={currentUser} key={room.name} roomSelected={currentRoom.name}></RoomCard>
                      </div>

                    );
                  } )
                ) : ( <h3> No hay Chats</h3>)
              }
            </Col>

            <Col md={9} className='chat-space'>
              {
                (chatList.length > 0)?(
                  renderChat()
                ) : ( <h3> No hay Chats</h3>)
              }
            </Col>
          </Row>

        </div>
      )
    }
    else{
      return(
        <NoUser/>
      )

    }
  }

  return (
    showLoading()
  );
}
export default ChatContainer;
