export const orderChatRooms = (cRooms) => {
    if(cRooms.length > 1) {
      cRooms.sort((a, b) => {
        let updatedAtA = a.hasOwnProperty("updated_at") ? a.updated_at : 0;
        let updatedAtB = b.hasOwnProperty("updated_at") ? b.updated_at : 0;
  
        if(updatedAtA > updatedAtB) {
          return -1;
        }
  
        if(updatedAtA < updatedAtB) {
          return 1;
        }
  
        return 0;
      });
    }
    return cRooms;
}