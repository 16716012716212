import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/database'
import ENV from '../environment';

const fb_api_key = ENV.fb_api_key;
const fb_auth_domain = ENV.fb_auth_domain;
const fb_db_url = ENV.fb_db_url;

const fbConfig = {
    apiKey: fb_api_key,
    authDomain: fb_auth_domain,
    databaseURL: fb_db_url
}

firebase.initializeApp(fbConfig)

export default firebase;