import React, {useEffect,useState} from 'react'
import {Row, Col, FormControl } from 'react-bootstrap'
import RoomCard from './RoomCard';
import {  Link} from 'react-router-dom';
import ReactLoading from 'react-loading'
import { getRoomInfo } from '../services/firebase'
import { useSelector } from 'react-redux'
import { orderChatRooms } from '../helpers/helpChatRoom';
import { isEmpty } from 'react-redux-firebase'
import './ChatList.scss'

function ChatList(props){
  var rooms = props.rooms
  const currentUser = props.user
  var error = props.error

  const chatRooms = useSelector(state => state.firebase.data['chatrooms'])

  const [loading, setLoading] = useState(true);
  const [empty, setEmpty] = useState(false);
  const [message, setMessage] = useState('Loading...');
  const [currentSearch, setCurrentSearch] = useState('');

  const arrayChatRooms = React.useMemo(()=> {
    return isEmpty(chatRooms) ? [] : orderChatRooms(Object.values(chatRooms));
  }, [chatRooms]);

  const filteredChatList = React.useMemo(()=> {
    const _filteredChats = !isEmpty(chatRooms) ? arrayChatRooms.filter((chatRoom) => {
      return chatRoom.hasOwnProperty('chat_name') ? chatRoom.chat_name
          .toUpperCase()
          .includes(currentSearch.toUpperCase()) : true;
    }) : [];

    return _filteredChats;
  }, [chatRooms, currentSearch]);

  const handleSearch = (searchQuery) => {
    setCurrentSearch(searchQuery);
  };

  useEffect(() => {
    if(rooms.length > 0 & rooms.length === arrayChatRooms.length){
      setTimeout(()=> {setLoading(false)}, 500)
      setEmpty(false);
    }else{
      setMessage('Sin Chats');
      setEmpty(true);
    }

  }, [rooms]);

  useEffect(() => {
    if(error){
      setLoading(false)
    }
  }, [error]);


  useEffect(()=> {
    if(arrayChatRooms.length > 0 && arrayChatRooms.length === rooms.length){
      setLoading(false);
    }
  }, [arrayChatRooms.length]);

  return(
    <div className='chat-container'>
        <Row className='chat-row'>
          <Col md={12} className='chat-list'>
            <div className='header'>
              <h2>Chats</h2>
            </div>
            <div className='input-search-mobil'>
                <FormControl 
                  ype="text"
                  placeholder='Buscar chat'
                  className='search-box'
                  onChange={(e) => handleSearch(e.target.value)}></FormControl>
              </div>
            {(loading)?(<ReactLoading type='spinningBubbles' color="#000" />): null}
            {

              (error)?( <h1></h1>):

              filteredChatList.map(room => {
                  return (
                    <Link className='link' to='/chat' state={{ user: currentUser , room: room.name, chatRoom: room, name: 'c', rooms: rooms}} key={`${room.name}-link`}>
                      <RoomCard room={room} user={currentUser} key={room.name}></RoomCard>
                    </Link>

                  );
                } )


            }
            <div className='empty-message'>
              {(empty)?(<h1>{message}</h1>): null}
            </div>
          </Col>
        </Row>

      </div>
  )
}
export default ChatList;

